import React, { Suspense } from 'react';
import { createRoot } from "react-dom/client";
import App from './App';
import './i18n';
import * as serviceWorker from './serviceWorker';

const domNode = document.getElementById("root");
const root = createRoot(domNode);

root.render(
  <Suspense fallback={<div>Loading...</div>}>
    <App />
  </Suspense>,
);

serviceWorker.unregister();